<template>
    <main>
		<div class="services-area pt-50 pb-50">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3>Downloads</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <ul id="accordion" class="accordion">
                            <li>
                                <div class="link"><i class="fa fa-file-text"></i>Protected Download<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <div class="row">
                                                        <div class="col-md-4 xs-cls">
                                                            <form class="contact-form" id="download-form">
                                                                <input name="password" id="password" type="password" placeholder="Enter password" />
                                                            </form>
                                                        </div>
                                                        <div class="col-md-4 xs-cls">
                                                            <button type="button" @click="validate()" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>FreightMan<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcWDlZTjJJVm5Kc3c/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>Cargo Manager<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcVGtrTmlRTVowZ3M/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <!-- <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div> -->
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>EXIMax<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcaDJzU1U3aHFJVk0/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUU5kRWtoOHpIRE0/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>FoodNote<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCceDdsOHBMNXJSTkk/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <!-- <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div> -->
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>BizBank<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcakNzaHBZSE5hLU0/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <!-- <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div> -->
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>LogMagix<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcMmZ4R2FrX1JhTFE/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <!-- <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div> -->
                                            </div>
                                            <div class="col-md-4">
                                                <!-- <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div> -->
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>Scheme Manager<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCceHBtalUtczdUaDQ/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <!-- <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div> -->
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>Stock Outlook<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcTFo3UUhVeDhHcVk/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <!-- <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div> -->
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>TradeBazix<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcVjNHbm5mV3FFQ0U/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcYllycHlLZ2hFYVE/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <div class="link"><i class="fa fa-server"></i>Visual Shipment<i class="fa fa-chevron-down"></i></div>
                                <ul class="submenu">
                                    <li>
                                        <div class="row">
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">Setup</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcTERlVzFEYXJKbkU/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <!-- <div class="d-title">Brochure</div>
                                                <div class="d-content">
                                                    <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcUW5yODRSdzhndDA/view?usp=sharing" class="btn btn-primary btn-sm"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                                </div> -->
                                            </div>
                                            <div class="col-md-4 xs-cls">
                                                <div class="d-title">SQL Server</div>
                                                <div class="d-content">
                                                    <ul>
                                                        <li class="d-content-li-title">For Windows 7</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNFg4SXZUdlYzdTA/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcOEUxcUpZRkxtRTQ/view?usp=sharing" class="download-button">64 bit</a></li>
                                                        <li class="d-content-li-title">For Windows 10</li>
                                                        <li>Download : <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNHZnMk9QYVdJVGs/view?usp=sharing" class="download-button">32 bit</a> / <a href="https://drive.google.com/file/d/0B0LD7OXv0fCcM3NzQlZXV2xZSG8/view?usp=sharing" class="download-button">64 bit</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>   
                    </div>
                    <div class="col-md-4">
                        <div class="servicee-sidebar mb-50">
							<div class="sidebar-link grey-bg">
								<h3>STUCK SOMEWHERE ?</h3>
								<div class="support_sidebar">
									<p>
										We are here to help you out. You can learn from our video
										tutorials, ask a question, submit a ticket or contact us
										directly.
									</p>
									<p>
										You can also opt to get personalized support through remote
										desktop software, however, we recommend to go through the
										video tutorials first, if those are of any benefit to you.
									</p>
								</div>

								<!-- <h3>GOT A QUESTION ?</h3>
								<div class="support_sidebar">
									<p>
										Ask us, We will definitely look into your case. You can also
										submit a support ticket and we will be right there to help
										you out at the earliest.
									</p>
								</div> -->

								<h3>CONTACT SUPPORT</h3>
								<ul>
									<li><b>We work</b></li>
									<li>Mon-Sat, 10:30 AM to 8:00 PM (IST)</li>
									<li>support@xtrm.co.in</li>
									<li>+91 9662 623471</li>
								</ul>

								<!-- <h3>CATEGORIES</h3>
								<ul>
									<li><a href="javascript:void(0)" @click="$router.push('/products')">Fleet Manager</a></li>
									<li><a href="javascript:void(0)" @click="$router.push('/products')">FreightMan</a></li>
									<li><a href="javascript:void(0)" @click="$router.push('/products')">General</a></li>
								</ul> -->
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import $ from 'jquery'
import swal from 'sweetalert2';
window.Swal = swal;
// const Swal = new SweetAlert2();
export default {
    mounted(){
        this.accordian();
    },

    methods: {
        accordian($this, $next) {
            var Accordion = function(el, multiple) {
                this.el = el || {};
                this.multiple = multiple || false;

                var links = this.el.find('.link');

                links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown)
            }

            Accordion.prototype.dropdown = function(e) {
                var $el = e.data.el;
                $this = $(this),
                $next = $this.next();

                $next.slideToggle();
                $this.parent().toggleClass('open');

                if (!e.data.multiple) {
                $el.find('.submenu').not($next).slideUp().parent().removeClass('open');
                };
            }

            var accordion = new Accordion($('#accordion'), false);
        },

        // method for password validation
        validate()
			{
                if(document.getElementById('password').value == '123456799'){
                   // window.open("https://drive.google.com/file/d/0B0LD7OXv0fCcd1U5ODVYdWFUeUU/view?usp=sharing");
                   window.location.href="https://drive.google.com/file/d/0B0LD7OXv0fCcd1U5ODVYdWFUeUU/view?usp=sharing";
                   document.getElementById("download-form").reset();
                }
				else if(document.getElementById('password').value == ''){
                    return new Swal("Oops!", "Pleaes Enter Password!!!", "error");
                    // document.getElementById("download-form").reset();
				}else{
                    return new Swal("Oops!", "wrong password please try again!!!", "error");
                    // document.getElementById("download-form").reset();
				}
			}
    }
}
</script>

<style lang="scss">
ul {
    list-style-type: none
}

a {
    color: #b63b4d;
    text-decoration: none
}

.accordion {
    width: 100%;
    margin: 0px auto 20px;
    background: #FFF;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 5px
}

.accordion .link {
    cursor: pointer;
    display: block;
    margin-top: 5px;
    padding: 15px 15px 15px 42px;
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid #CCC;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease
}

.accordion li:last-child .link {
    // border-bottom: 0
}

.accordion li i {
    position: absolute;
    top: 16px;
    left: 12px;
    font-size: 18px;
    color: #595959;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease
}

.accordion li i.fa-chevron-down {
    right: 12px;
    left: auto;
    font-size: 16px
}

.accordion li.open .link {
    color: #d33327
}

.accordion li.open i {
    color: #d33327
}

.accordion li.open i.fa-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
}

.submenu {
    display: none;
    background: #ffffff;
    font-size: 14px
}

.submenu li {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px;
}

.submenu a {
    display: block;
    text-decoration: none;
    color: #4b4a5e;
    padding: 12px;
    padding-left: 42px;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease
}

/* .submenu a:hover {
    background: #d33327;
    color: #FFF
} */
.d-title{
    font-weight: bold;
}
.d-content{
    margin-top: 15px;
}
.btn-sm:hover .fa-download{
    color: #FFF;
}
.fa-download{
    position: static !important;
    margin-right: 5px;
    font-size: 11px !important;
}
.d-content ul li{
    border: 1px solid #ccc;
}
.d-content-li-title{
    font-weight: bold;
}
.d-content a{
    // padding: 5px 15px;
    // width: 65%;
}
.download-button{
    display: inline-block !important;
    padding-left: 0 !important;
    padding: 0px !important;
    font-weight: bold;
    width: auto !important;
}
.download-button:hover{
    color: #d33327;
}
</style>